<template>
  <v-container class="flex" id="main-container" fluid>
    <form>
      <v-row justify="center">
        <v-col cols="12" md="10" lg="5" xl="5">
          <v-card class="mt-16" style="opacity: 0.85; background-color: #b0d2ec">
            <!--            business name-->
            <v-row>
              <v-col cols="12">
                <p class="text-h4" style="text-align: center">
                  Work Schedule
                </p>
              </v-col>
            </v-row>
            <!--            show session end alert-->
            <v-row justify="center" v-if="$route.query && $route.query.sessionEnd === true">
              <v-col cols="10" xs="8" sm="7" md="7">
                <v-alert
                    dense
                    text
                    type="warning"
                    border="bottom"
                    dismissible>
                  Session ended. <br/><strong>Login</strong> again!
                </v-alert>
              </v-col>
            </v-row>
            <!--            username field-->
            <v-row justify="center">
              <v-col cols="10" xs="8" sm="6" md="5">
                <v-text-field
                    v-model="username"
                    required
                    autocomplete="off"
                    label="Username"
                    ref="usernameField"/>
              </v-col>
            </v-row>
            <!--            password field-->
            <v-row justify="center">
              <v-col cols="10" xs="8" sm="6" md="5">
                <v-text-field
                    v-model="password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    hint="At least 6 characters"
                    counter
                    required
                    @click:append="showPassword = !showPassword"
                    autocomplete="off"
                    label="Password"
                    clearable/>
              </v-col>
            </v-row>
            <!--            login button and loading overlay-->
            <v-row justify="center">
              <v-btn
                  class="mb-8 mt-4 pl-12 pr-12 pb-4 pt-4"
                  outlined
                  color="dark"
                  big
                  @click.prevent="loginAuth"
                  type="submit"
                  :elevation="3">
                Login
              </v-btn>
              <v-overlay :value="showOverlay">
                <v-progress-circular indeterminate size="50" color="amber"/>
              </v-overlay>
            </v-row>
            <!--            login error alert message-->
            <v-row justify="center">
              <v-col cols="10" xs="10" sm="10" md="10">
                <v-alert
                    v-if="loginErrorMessage.length > 0"
                    type="warning"
                    icon="mdi-alert"
                    dense>
                  {{ loginErrorMessage }}
                </v-alert>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </form>
    <v-spacer></v-spacer>
    <v-row space-between>
      <About/>
    </v-row>
    <!--            server down error bottom sheet-->
    <v-bottom-sheet
        v-model="serverIsDown"
        persistent
        dark>
      <v-sheet
          class="text-center"
          height="200px">
        <v-btn
            class="mt-6"
            outlined
            text
            color="green"
            @click="checkServerStatus">
          Retry
        </v-btn>
        <div class="py-3">
          Connection failed!
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </v-container>
</template>

<script>
import {UserLogin} from "@/model/user/UserLogin";
import UserService from "@/service/UserService";
import ServerService from "@/service/ServerService";
import About from "@/components/About";
import {BackendError} from "@/exceptions/BackendError";

export default {
  name: "login",
  components: {About},
  data: function () {
    return {
      username: '',
      password: '',
      showPassword: false,
      showOverlay: false,
      loginErrorMessage: '',
      serverIsDown: false,
    }
  },
  mounted() {
    this.checkServerStatus();
    this.$refs.usernameField.focus();
  },
  methods: {
    async checkServerStatus() {
      const serverUpResponse = await ServerService.isServerUp();
      this.serverIsDown = !serverUpResponse;
    },
    async loginAuth() {
      this.showOverlay = true;
      try {
        await UserService.login(new UserLogin(this.username, this.password));
      } catch (e) {
        this.showOverlay = false;
        if (e instanceof BackendError) {
          this.loginErrorMessage = e.message;
          return;
        }
        this.loginErrorMessage = "Request failed!";
        return;
      }
      this.showOverlay = false;
      await this.$router.push({name: 'Home'});
    }
  }
}
</script>

<style scoped>
#main-container {
  background: url('~@/assets/login-bck.png') no-repeat top center;
  background-size: cover;
  height: 100vh;
}

html, body {
  margin: 0;
  padding: 0;
  min-width: 100%;
  width: 100vw;
}
</style>