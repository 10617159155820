import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login'
import UserService from "@/service/UserService";

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import('../views/Schedule'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/schedule',
		name: 'Schedule',
		component: () => import('../views/Schedule'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/schedules',
		name: 'ScheduleFilter',
		component: () => import('../views/ScheduleFilter'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/notes',
		name: 'Notes',
		component: () => import('../views/Notes'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/user',
		name: 'Users',
		component: () => import('../views/Users'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/reset-password',
		name: 'ResetPassword',
		component: () => import('../views/ResetPassword'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/info',
		name: 'Info',
		component: () => import('../views/Company.vue')
	},
	{
		path: '/settings',
		name: 'Settings',
		component: () => import('../views/Settings')
	},
	{
		path: '/login',
		name: 'Login',
		component: Login
	},
	{
		path: '/login/:sessionEnd',
		name: 'loginWithSessionMessage',
		component: Login
	},
	{
		path: "*",
		component: null //todo: create page for missing-404
	}
]

const router = new VueRouter({
	mode: 'history',
	routes
})

/**
 * router security and token-expiration check
 */
router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (UserService.currentToken()) {
			if (UserService.isTokenActive()) {
				next();
				return;
			} else {
				next({name: 'Login'});
				return;
				/*if (from) { //if its not the first time loading the app
					console.log('send prop to login with message -sesioni ka perfunduar-');
					//todo:  send prop to login with message 'sesioni ka perfunduar'
					return;
				}*/
			}
		}
		next({name: 'Login'});
	} else { //does not require auth
		next();
	}
})

export default router
