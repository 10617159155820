import axios from 'axios';
import {UserInfo} from "@/model/user/UserInfo";
import {BackendError} from "@/exceptions/BackendError";
import {UserUpdatePasswordDTO} from "@/model/user/UserUpdatePasswordDTO";
import moment from "moment";

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

class UserService {

	/**
	 * return current logged user object or null if not found
	 * @returns {null|UserInfo}
	 */
	getCurrentUser() {
		const currentUser = localStorage.getItem('current-user');
		if (!currentUser)
			return null;
		return JSON.parse(currentUser);
	}

	isCurrentUserAdmin() {
		const currentUser = localStorage.getItem('current-user');
		if (!currentUser)
			return false;
		return JSON.parse(currentUser).role === 'ROLE_ADMIN';
	}

	setCurrentUser(userInfo) {
		localStorage.setItem('current-user', JSON.stringify(userInfo));
	}

	currentToken() {
		return localStorage.getItem('token');
	}

	/**
	 * check if token exists and not expired yet
	 * @returns {boolean}
	 */
	isTokenActive() {
		const currentExpiration = localStorage.getItem('token-expiration');
		const momentDate = moment(currentExpiration, 'DD-MM-yy');
		if (!currentExpiration) {
			return false;
		} else {
			const compRes = this.compare(momentDate.toDate(), new Date());
			if (compRes <= 0 || isNaN(compRes)) {
				return false;
			}
		}
		return true;
	}

	// Compare two dates (could be of any type supported by the convert
	// function above) and returns:
	//  -1 : if a < b
	//   0 : if a = b
	//   1 : if a > b
	// NaN : if a or b is an illegal date
	// NOTE: The code inside isFinite does an assignment (=).
	compare(a, b) {
		return (
			isFinite(a = this.convertDate(a).valueOf()) &&
			isFinite(b = this.convertDate(b).valueOf()) ? (a > b) - (a < b) : NaN
		);
	}

	// Converts the date in d to a date-object. The input can be:
	//   a date object: returned without modification
	//  an array      : Interpreted as [year,month,day]. NOTE: month is 0-11.
	//   a number     : Interpreted as number of milliseconds
	//                  since 1 Jan 1970 (a timestamp)
	//   a string     : Any format supported by the javascript engine, like
	//                  "YYYY/MM/DD", "MM/DD/YYYY", "Jan 31 2009" etc.
	//  an object     : Interpreted as an object with year, month and date
	//                  attributes.  **NOTE** month is 0-11.
	convertDate(dt) {
		return (
			dt.constructor === Date ? dt :
				dt.constructor === Array ? new Date(dt[0], dt[1], dt[2]) :
					dt.constructor === Number ? new Date(dt) :
						dt.constructor === String ? new Date(dt) :
							typeof dt === "object" ? new Date(dt.year, dt.month, dt.date) :
								NaN
		);
	}

	/**
	 * call api and return auth response status + message
	 * <p>save token and token-expiration to localstorage</p>
	 * @param userLoginDTO username + password
	 * @throws BackendError with message from server or default message
	 */
	login(userLoginDTO) {
		return axios.post(API_BASE_URL + 'authenticate', JSON.stringify(userLoginDTO),
			{
				headers: {'Content-Type': 'application/json; charset=utf-8'}
			}).then(response => {
			const body = response.data;
			localStorage.setItem('token', body.token);
			localStorage.setItem('token-expiration', body.tokenExpiration);
			this.setCurrentUser(new UserInfo(body.id, body.username, body.role));
			if (response.status === 400 || response.status === 403) {
				throw BackendError("Kredencialet janë dhënë gabim!");
			} else {
				return new BackendError(false, response.data.errorMessage);
			}
		})
			.catch((ex) => {
				if (ex.response) {
					if (ex.response.data) {
						throw new BackendError(ex.response.data.errorMessage);
					}
				}
				throw new BackendError("Problem during request handling!");
			});
	}

	getAllActive() {
		return axios.get(API_BASE_URL + 'users/',
			{
				headers: {
					Authorization: 'Bearer ' + this.currentToken(),
					ContentType: 'application/json; charset=utf-8'
				}
			}).then(response => {
			return response.data;
		})
			.catch((ex) => {
				if (ex.response) {
					if (ex.response.data) {
						throw new BackendError(ex.response.data.errorMessage);
					}
				}
				throw new BackendError("Problem during request handling!");
			});
	}

	getWorkersWithNoSchedules() {
		return axios.get(API_BASE_URL + 'users/worker/no-schedule',
			{
				headers: {
					Authorization: 'Bearer ' + this.currentToken(),
					ContentType: 'application/json; charset=utf-8'
				}
			}).then(response => {
			return response.data;
		})
			.catch((ex) => {
				if (ex.response) {
					if (ex.response.data) {
						throw new BackendError(ex.response.data.errorMessage);
					}
				}
				throw new BackendError("Problem during request handling!");
			});
	}

	getAllRoles() {
		return axios.get(API_BASE_URL + 'users/roles',
			{
				headers: {
					Authorization: 'Bearer ' + this.currentToken(),
					ContentType: 'application/json; charset=utf-8'
				}
			}).then(response => {
			return response.data;
		})
			.catch((ex) => {
				if (ex.response) {
					if (ex.response.data) {
						throw new BackendError(ex.response.data.errorMessage);
					}
				}
				throw new BackendError("Problem during request handling!");
			});
	}

	/**
	 * send delete request to api for given user id
	 * @param userId
	 */
	deleteById(userId) {
		axios.delete(API_BASE_URL + 'users/' + userId,
			{
				headers: {
					Authorization: 'Bearer ' + this.currentToken()
				}
			}).then(() => {
		})
			.catch((ex) => {
				if (ex.response) {
					if (ex.response.data) {
						throw new BackendError(ex.response.data.errorMessage);
					}
				}
				throw new BackendError("Problem during request handling!");
			});
	}

	/**
	 * send post to api to create new user
	 * @param userInsertDTO with appropriate role naming convention
	 */
	add(userInsertDTO) {
		return axios.post(API_BASE_URL + 'users/', JSON.stringify(userInsertDTO),
			{
				headers: {
					Authorization: 'Bearer ' + this.currentToken(),
					'Content-Type': 'application/json; charset=utf-8'
				}
			}).then(response => {
			return response.data;
		})
			.catch((ex) => {
				if (ex.response) {
					if (ex.response.data) {
						throw new BackendError(ex.response.data.errorMessage);
					}
				}
				throw new BackendError("Problem during request handling!");
			});
	}

	/**
	 * send post to api to create new user
	 * @param userUpdateDTO with appropriate role naming convention
	 */
	update(userUpdateDTO) {
		return axios.put(API_BASE_URL + 'users/', JSON.stringify(userUpdateDTO),
			{
				headers: {
					Authorization: 'Bearer ' + this.currentToken(),
					'Content-Type': 'application/json; charset=utf-8'
				}
			}).then(response => {
			return response.data;
		})
			.catch((ex) => {
				if (ex.response) {
					if (ex.response.data) {
						throw new BackendError(ex.response.data.errorMessage);
					}
				}
				throw new BackendError("Problem during request handling!");
			});
	}

	/**
	 * send put to api to change password of current user
	 * @param currentPassword
	 * @param newPassword
	 */
	updatePassword(currentPassword, newPassword) {
		const userPassword = new UserUpdatePasswordDTO(currentPassword, newPassword);
		axios.put(API_BASE_URL + 'users/reset-password', JSON.stringify(userPassword),
			{
				headers: {
					Authorization: 'Bearer ' + this.currentToken()
				}
			}).then(response => {
			return response.data;
		})
			.catch((ex) => {
				if (ex.response) {
					if (ex.response.data) {
						throw new BackendError(ex.response.data.errorMessage);
					}
				}
				throw new BackendError("Problem during request handling!");
			});
	}

	/**
	 * clear local storage values
	 */
	logout() {
		localStorage.setItem('token', '');
		localStorage.setItem('token-expiration', '');
		localStorage.setItem('current-user', '');
	}
}

export default new UserService();