import axios from 'axios';

class ServerService {

	/**
	 * @return {Promise<boolean>} true if server is UP
	 */
	isServerUp() {
		return axios.get(process.env.VUE_APP_API_BASE_URL + 'status',
			{
				headers: {'Content-Type': 'application/json; charset=utf-8'}
			}).then(response => response.status === 200)
			.catch(() => false);
	}
}

export default new ServerService();